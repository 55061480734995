  /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSelector , useDispatch} from 'react-redux'
import { getApwindow, addStudentsByFile} from "../../../actions/apwindowsActions"
import useGetRequest from "../../../customHooks/useGetRequest"
import usePostRequest from "../../../customHooks/usePostRequest"
import moment from 'moment'
import Select from "react-select"
import Loading from "../../Loading"
import swal from "sweetalert2"
import {APWINDOWS_USR_DOC} from "../../../actions/constants"

const styles = {
  main: {
    display: 'flex', 
    minHeight: '70vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  box_style: {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  },
  noMargin: {
    margin: 0
  }
}


const AddStudents = (props) => {

  // id's segun la url
  const id = props.match.params.id
  const id_ventana = props.match?.params?.apwindow_id
  
  //estados
  const [selectData, setSelectData] = useState({ value: null, options: [] })
  const [file, setFile] = useState(null) // Estado para manejar el archivo xlsx
  const [fileLoading, setFileLoading] = useState(false) // Estado para la carga del archivo
  const [fileError, setFileError] = useState(null) // Estado para manejar los errores del archivo
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [nonExistingStudents, setNonExistingStudents] = useState('');



  // custom hooks
  const { data: studensList } = useGetRequest(`apwindows/${id_ventana}/users_available`);
  const { sendRequest:addByGroup, loading:createGroupLoading, data:createGroupData, error: createGroupError } = usePostRequest('apwindow_user_options/addGroup');

  // estados del reducer
  const apwindow = useSelector(state => state?.apwindows?.apwindow)
  
  // despachador
  const dispatch = useDispatch()

  // eventos
  const addStudents = (e) => {
    e.preventDefault()
    const data = {
      apwindow_id: id_ventana,
      students: selectData.value.map(obj=> obj.value)
    }
    addByGroup(data)

  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]) // Guardamos el archivo seleccionado en el estado
  }
  
  const uploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      setFileError("Por favor selecciona un archivo.");
      return;
    }
  
    setFileLoading(true);
    setFileError(null);
  
    // Asegúrate de enviar el exam_id además del apwindow_id y el archivo
    dispatch(addStudentsByFile(id_ventana, id, file, 
      (response) => {
        const { added_students, failed_students, existing_students, invalid_emails, non_existing_students} = response;
  
        // Limpiamos los mensajes anteriores
        setSuccessMessage('');
        setWarningMessage('');
        setErrorMessage('');
        setNonExistingStudents('');
  
        // Mensajes para swal
        let successMessage = '';
        let warningMessage = '';
        let errorMessage = '';
        let nonExistingMessage = '';
  
        // Mensaje para los estudiantes agregados
        if (added_students.length > 0) {
          successMessage = `${added_students.length} estudiantes agregados con éxito.`;
          setSuccessMessage(added_students); // Guardamos los estudiantes para mostrarlos en el DOM
        }
  
        // Mensaje para los estudiantes ya registrados (advertencia)
        if (existing_students.length > 0) {
          warningMessage = `Ya estaban registrados ${existing_students.length} estudiantes.`;
          setWarningMessage(existing_students); // Guardamos las advertencias para mostrarlas en el DOM
        }
  
        // Mensaje para los estudiantes que fallaron y correos inválidos
        if (failed_students.length > 0 || invalid_emails.length > 0) {
          errorMessage = `Hubo errores con ${failed_students.length} estudiantes y ${invalid_emails.length} correos inválidos.`;
          setErrorMessage([...failed_students, ...invalid_emails]); // Guardamos los errores para mostrarlos en el DOM
        }
  
        // Mensaje para los estudiantes no existentes
        if (non_existing_students.length > 0) {
          nonExistingMessage = `${non_existing_students.length} estudiantes no existen en el sistema.`;
          setNonExistingStudents(non_existing_students); // Guardamos los estudiantes no existentes para mostrarlos en el DOM
        }
  
        // Mostrar resumen en swal
        swal({
          type: successMessage ? 'success' : 'warning',
          title: successMessage ? 'Operación completada' : 'Advertencia',
          html: `${successMessage}<br/>${warningMessage}<br/>${errorMessage}<br/>${nonExistingMessage}`,
          showConfirmButton: true
        });
  
        setFileLoading(false);
      }, 
      (error) => {
        setFileError("Error al subir el archivo.");
        console.error(error);
        setFileLoading(false);
      }
    ));
  };  
  
  

  const renderError = (error) => {
    if (!error.response) return (<li className="has-error">{error.message}</li>)
    let {data} = error?.response
    const isArray = Array.isArray(data)
    if (typeof data !== 'object'  && !isArray) return (<li className="has-error">{error.message}</li>)
    if (isArray) data = data[0]
    return (<ul className="text-danger">
      {data.exception ? (
        <li key={`${data.error}_${data.status}`}>{data.exception}</li>
      ) : Object.keys(data).map((field, i) => (<React.Fragment key={`${field}_${i}`}>
        {data[field]?.map((reason, i) => (
          <li key={`${reason}_${i}`}>{reason}</li>
        ))}
      </React.Fragment>))}
    </ul>)
  }
  
  const successUpdate = () => {
    swal({
      type: 'success',
      title: `${selectData.value.length} estudiantes agregados a la ventana ${apwindow.title}`,
      showConfirmButton: false,
      timer: 1000
    }).then(()=> props.history.push(`/exams/${id}/apwindows`))
  }

  const selectAll = (e) => {
    e.preventDefault()
    setSelectData({...selectData, value: selectData.options})
  }

  // efectos
  useEffect(() => {
    dispatch(getApwindow(id_ventana,()=>{}, ()=> {}))
  },[])

  useEffect(() => {
    if (studensList) {
      setSelectData({ ...selectData, options: studensList.map(student => ({ label: student.email, value: student.id })) })
    }
  }, [studensList])

  if (createGroupLoading || fileLoading) return (<Loading />)
  if (createGroupData) return <>{successUpdate()}</>

	return (<form onSubmit={addStudents}>
    <main style={styles.main}>
        <section>
          <h1>Añadir estudiantes</h1>
          <br />
          <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'end', justifyContent: 'space-between'}}>
            <div>
              <p style={styles.noMargin}>Examen:</p>
              <h2 style={styles.noMargin}>{apwindow?.exam?.title}</h2>
            </div>
            <div style={{minWidth: '325px'}}>
              <p style={styles.noMargin}>
                <b>Comienza:</b> {moment(apwindow?.exam?.initialDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
              <p style={styles.noMargin}>            
                <b>Finaliza:</b> {moment(apwindow?.exam?.finalDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
            </div>
          </div>
          <br/>
          <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'end', justifyContent: 'space-between'}}>
            <div>
              <p style={styles.noMargin}>Ventana:</p>
              <h2 style={styles.noMargin}>{apwindow?.title}</h2>
            </div>
            <div style={{minWidth: '325px'}}>
              <p style={styles.noMargin}>
                <b>Comienza:</b> {moment(apwindow?.startDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
              <p style={styles.noMargin}>            
                <b>Finaliza:</b> {moment(apwindow?.deadline).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
            </div>
          </div>
          <br />
          <div style={{padding: '20px', display: "flex", flexFlow: "row wrap", justifyContent: 'space-between', alignItems: 'end'}}>
            <div style={{flexGrow: 1, marginRight: '1em'}}>
              <label>Estudiante</label>
              <Select
                onChange = {value => setSelectData({ ...selectData, value,})}
                value={selectData.value}
                multi={true}
                options={selectData.options}
                isClearable
                required
                placeholder='selecciona una ventana...'
              />
            </div>
            <button
              onClick={selectAll}
              className="btn btn-info" title="seleccionar todos">
              Seleccionar todos
            </button>
          </div>
          <div style={{margin: '2rem'}}>
            {(createGroupError) && renderError(createGroupError)}
          </div>
           {/* Subida del archivo xlsx */}
           <div>
            <h2>Asociar estudiantes</h2>
            <p>
              A continuación, podrá asociar estudiantes a ventanas de aplicación en la opción. Se asociarán los correos con la ventana de aplicación 
              de una lista en Excel (columna <strong>email</strong>).
            </p>
            <p>Ejemplo:</p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>estudiante@correo.edu.co</td>
                </tr>
                <tr>
                  <td>estudiante@correo.edu.co</td>
                </tr>
                <tr>
                  <td>estudiante@correo.edu.co</td>
                </tr>
              </tbody>
            </table>
            <a href={APWINDOWS_USR_DOC} target="_blank" rel="noopener noreferrer">Descargar plantilla</a>
          </div>
           <div style={{ margin: '0rem' }}>
            <label>Subir archivo de estudiantes (xlsx)</label>
            <input type="file" onChange={handleFileChange} accept=".xlsx" />
            <button onClick={uploadFile} className="btn btn-info" disabled={fileLoading}>Subir archivo</button>
            {fileError && <p className="text-danger">{fileError}</p>}
          </div>
          <div style={{ marginBottom: '20px' }}>
            {successMessage.length > 0 && (
              <div className="alert alert-success">
                <h4>Estudiantes agregados con éxito: ({successMessage.length})</h4>
                <ul className="scrollable-list">
                  {successMessage.map((student, index) => (
                    <li key={index}>{student.email}</li>
                  ))}
                </ul>
              </div>
            )}

            {warningMessage.length > 0 && (
              <div className="alert alert-warning">
                <h4>Estudiantes ya registrados (Advertencia) ({warningMessage.length}):</h4>
                <ul className="scrollable-list">
                  {warningMessage.map((student, index) => (
                    <li key={index}>{student.email}</li>
                  ))}
                </ul>
              </div>
            )}

            {errorMessage.length > 0 && (
              <div className="alert alert-danger">
                <h4>Errores al agregar estudiantes: ({errorMessage.length})</h4>
                <ul className="scrollable-list">
                  {errorMessage.map((error, index) => (
                    <li key={index}>{error.email} - {error.reason}</li>
                  ))}
                </ul>
              </div>
            )}

            {nonExistingStudents.length > 0 && (
              <div className="alert alert-danger">
                <h4>Estudiantes no existentes en el sistema: ({nonExistingStudents.length})</h4>
                <ul className="scrollable-list">
                  {nonExistingStudents.map((student, index) => (
                    <li key={index}>{student.email}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>       
        </section>			
        <section 
          style={{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => props.history.push(`/exams/${id}/apwindows`)}
            type="button"
            className="btn btn-danger">
            Volver
          </button>
          <button
            type="submit"
            className="btn btn-info">
            Guardar estudiantes
          </button>
        </section>
    </main>
  </form>)
}

export default AddStudents