import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de acciones sobre el estado de los examenes.

// Actualización de los examenes en el estado
export const GET_EXAMS = 'GET_EXAMS';

// Actualización del examen actual en el estado
export const GET_EXAM = 'GET_EXAM';

// Remover un examen dado del estado
export const DELETE_EXAM = 'DELETE_EXAM';

// Actualización de los examenes en el estado
export const GET_SEMESTERS = 'GET_SEMESTERS';

// Envío de notificaciones de calificaciones 
export const SEND_EMAILS = 'SEND_EMAILS';



/**
 * Obtiene todos los examenes disponibles en la aplicación.
 *
 * @export getExams
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExams(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exams${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAMS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Obtiene el examen con id dado por parámetro.
 *
 * @export getExam
 * @param {*} id : El id del examen a obtener.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExam(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/exams/${id}`, getAuthHeaders());
  
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea un examen con los datos dados por parámetro
 *
 * @export createExam
 * @param {*} exam : Información del examen a crear.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createExam(exam, successCallback = () => {}, errorCallback = (error) => {}) {
  
  const request = axios.post(`${ROOT_URL}/exams`, {exam: exam}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback(data.id);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza el examen con id dado por parámetro con la información en el objeto exam dado
 *
 * @export updateExam
 * @param {*} id : El id del examen a actualizar.
 * @param {*} exam : La información del examen a actualizar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateExam(id, exam, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}`, {exam: exam}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Actualiza el examen con id dado por parámetro con la información en el objeto exam dado
 *
 * @export updateExam
 * @param {*} id : El id del examen a actualizar.
 * @param {*} exam : La información del examen a actualizar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function finishExam(id,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/finish_exam`,{}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza los participantes (estudiantes) del examen con la información dada por parámetro.
 *
 * @export editParticipantsExam
 * @param {*} id : El id de examen cuyos participantes se desean editar.
 * @param {*} participantsInfo : Información de los participantes (estudiantes) del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editParticipantsExam(id, participantsInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/edit_participants`, participantsInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

// Acción para agregar estudiante por correo
export function addStudentByEmail(examId, studentEmail, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${examId}/add_student_by_email`, { email: studentEmail }, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,  // Actualiza el examen con la nueva lista de estudiantes
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  };
}


/**
 * agrega cuestionarios al examen
 *
 * @export addQuestionariesExam
 * @param {*} id : El id de examen al cual se desea agregar cuestionarios.
 * @param {*} questionariesInfo : Información de los participantes (estudiantes) del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function addQuestionariesExam(id, questionariesInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/add_mc_questionaries`, questionariesInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Asocia al examen con id dado por parámetro las respuestas con información de adjuntos dadas por parámetro.
 *
 * @export editAnswersExam
 * @param {*} id : El id del examen cuyos adjuntos de respuestas se quieren editar.
 * @param {*} answersInfo : La información de las repuestas que tienen un adjunto.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editAnswersExam(id, answersInfo,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/edit_answers_attachment`, answersInfo, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Borrado del examen con el id dado por parámetro.
 *
 * @export deleteExam
 * @param {*} id : El id del examen a eliminar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteExam(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/exams/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: DELETE_EXAM,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Borrado del examen con el id dado por parámetro.
 *
 * @export deleteExam
 * @param {*} id : El id del examen a eliminar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteExamStudent(id, student_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/delete_student`, {student_id: student_id}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_EXAM,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene las preguntas disponible.
 *
 * @export getSemesters
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getSemesters(successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/parameters/semesters`, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_SEMESTERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza el examen con id dado por parámetro con la información en el objeto exam dado
 *
 * @export sendEmailsToStudents
 * @param {*} id : El id del examen a actualizar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
*/

export function sendEmailsToStudents(id,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exams/${id}/send_emails_to_students`,{}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEND_EMAILS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}